import React from "react"
import { 
  useStaticQuery, 
  graphql,
  Link as GatsbyLink
} from "gatsby"

// import { Button } from 'gatsby-theme-material-ui';
// import { Typography } from "@material-ui/core";

import { 
  Box, 
  Container, 
  Link
} from "@chakra-ui/react"

import NavBar from "../components/navbar"
import ModalFormContact from "../components/modal-form-contact.js"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <>
        <NavBar />

        <Box className="page-wrapper" data-is-root-path={isHomePage} bg="primary.300" pb="4">
          <main>{children}</main>
          <Container maxW="container.lg" textAlign="center">
            <Box as="footer">
              © {new Date().getFullYear()} Rob Osborne Design Ltd<br/>
              10 Phelps Place, Grimsby, NE Lincs, DN32 8LY<br/>
              <Link to="/privacy-policy/" as={GatsbyLink} textDecoration="underline">Privacy Policy</Link>
            </Box>
          </Container>
        </Box>

        <ModalFormContact /> 
    </>
  )
}

export default Layout
