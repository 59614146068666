import React from "react"

import { ModalProvider } from "../components/modal-context"

export const Providers = (props) => {

    return (
        <ModalProvider>
            {props.children}
        </ModalProvider>
    )
}

 export default Providers
