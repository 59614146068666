import React, { useContext } from "react"

import {
  Box,
  Flex,
  Link,
  Heading,
  IconButton,
  Stack,
  Spacer,
  useColorModeValue,
  useDisclosure,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import { useStaticQuery, 
  graphql,
  Link as GatsbyLink
} from "gatsby"
import ButtonLink from "../components/button-link.js"

import { motion } from "framer-motion";

import { ModalContext } from "../components/modal-context.js"


const MenuList = (props) => {

  const allMenuItems = useStaticQuery(
    graphql`
      query menuItems {
        allWpMenuItem(
          filter: { locations: { eq: PRIMARY } }
          sort: { fields: order, order: ASC }
        ) {
          nodes {
            cssClasses
            label
            locations
            path
            target
            title
            url
            databaseId
            order
          }
        }
      }
    `
  )

  const [activeModal, setModal] = useContext(ModalContext);

  const setModalContact = () => {
    console.log('Opening contact modal');
    setModal('contact');
  }

  const buttonColorScheme = useBreakpointValue({ base: "whiteAlpha", md: "secondaryAlpha" });
  const buttonFont = useBreakpointValue({ base: "var(--chakra-fonts-heading)", md: "inherit" });


  // Menu items list
  let menuItems = allMenuItems.allWpMenuItem.nodes
  const MenuLinks = menuItems.map(item => (
    <ButtonLink
      as={GatsbyLink}
      to={item.path}
      variant="ghost"
      colorScheme={buttonColorScheme}
      isFullWidth="true"
      fontFamily={buttonFont}
      fontSize={{ base: '2xl', md: 'inherit' }}
      closeNav={props.closeNav}
    >
      {item.label}
    </ButtonLink>
  ))

  return (
    <Stack direction={["column", "row"]} spacing="4" 
    sx={{
      width: ['100%', 'auto'],
      height: ['100%', 'auto'],
      paddingBottom: [32, 0],
      paddingLeft: [4, 'auto'],
      paddingRight: [4, 'auto'],
      justifyContent: ['center', 'auto']
    }}>
      {MenuLinks}
      <Button 
        colorScheme={buttonColorScheme} 
        variant="outline" 
        fontFamily={buttonFont} 
        fontSize={{ base: '2xl', md: 'inherit' }}
        height={{ base: 12, md: 10 }}
        onClick={setModalContact}
      >
        Let’s talk
      </Button>
    </Stack>
  )

}




const NavBar = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  
  const isMdBreakpoint = useBreakpointValue({ base: false, md: true });

  // Prevent body scrolling when active
  React.useEffect(() => {
    if(isOpen && !isMdBreakpoint) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isOpen]);

  const openVariants = {
    closed: { opacity: 0 },
    open: { opacity: 1 },
  }
  
  return (
    <Box position="fixed" top="0" left="0" right="0" zIndex={1150}>
      <Flex
        bg={useColorModeValue('primary.300', 'gray.800')}
        color={useColorModeValue('gray.800', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('primary.500', 'gray.900')}
        align={'center'}>
          <Box>
            <Heading as="div" size="md"><Link as={GatsbyLink} to="/#">Rob Osborne <Box opacity="0.4" display="inline">Design</Box></Link></Heading>
          </Box>
          <Spacer />
          <Flex
            basis={{ base: 1, md: 'auto' }}
            grow="0"
            display={{ base: 'flex', md: 'none' }}
            justify="flex-end"
            align="right"
            >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              colorScheme={'secondaryAlpha'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <motion.div
            initial={{ opacity: 0 }}
            animate={(isOpen || isMdBreakpoint) ? "open" : "closed"}
            transition={{ 
              type: 'spring',
            }}
            variants={openVariants}
          >
            <Flex
              display="block"
              basis={{ base: 1, md: 'auto' }}
              grow="0"
              pointerEvents={isOpen ? { base: 'auto', md: 'auto' } : { base: 'none', md: 'auto' }}
              position={{ base: 'fixed', md: 'static' }}
              top={{ base: '0', md: 'auto' }}
              left={{ base: '0', md: 'auto' }}
              right={{ base: '0', md: 'auto' }}
              bottom={{ base: '0', md: 'auto' }}
              backgroundColor={{ base: 'secondary.500', md: 'transparent' }}
              zIndex={{ base: '2000', md: '0' }}              
              >
              <Flex justify={'flex-end'} px={4} py={3} display={{base: 'block', md: 'none' }} textAlign="right">
                <IconButton
                  onClick={onToggle}
                  icon={
                    isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                  }
                  variant={'ghost'}
                  colorScheme={'whiteAlpha'}
                  aria-label={'Toggle Navigation'}
                />
              </Flex>
              <MenuList closeNav={onToggle}/>
            </Flex>
          </motion.div>
        </Flex>

    </Box>
  )
}

export default NavBar
