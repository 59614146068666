import { Link, Button } from "@chakra-ui/react";
import React from "react";
import { Link as GatsbyLink } from "gatsby"
  
const ButtonLink = (props) => {

  return (
    <Link 
        _hover={undefined} 
        as={GatsbyLink}
        to={props.to}
    >
      <Button 
        colorScheme={props.colorScheme} 
        variant={props.variant} 
        isFullWidth={props.isFullWidth} 
        fontFamily={props.fontFamily} 
        fontSize={props.fontSize}
        size={props.size}
        paddingX={props.paddingX}
        onClick={props.closeNav}
      >
        {props.children}
      </Button>
    </Link>
  );
};

export default ButtonLink
