import React, { useState, createContext } from "react"

export const ModalContext = createContext(null)

export const ModalProvider = (props) => {

    const [activeModal, setModal] = useState(null)

    return (
        <ModalContext.Provider value={[activeModal, setModal]}>
            {props.children}
        </ModalContext.Provider>
    )
}

export default ModalProvider
