import React, { useContext } from "react";
import { 
  Box,
  Flex,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  IconButton,
} from "@chakra-ui/react"
import {
  CloseIcon,
} from '@chakra-ui/icons';
import { motion, AnimatePresence } from "framer-motion";

import { ModalContext } from "../components/modal-context.js"

export function ModalFormContact(props) {

  const [activeModal, setModal] = useContext(ModalContext);

  const closeModal = () => {
    console.log('Closing contact modal');
    setModal(null);
  }

  return (
    <AnimatePresence >
      {(activeModal === 'contact') ?
        <motion.div
          key="modal"
          initial={{ opacity: 0, zIndex: 4000, position: 'relative' }}
          animate={{ opacity: 1, zIndex: 4000, position: 'relative' }}
          exit={{ opacity: 0, zIndex: 4000, position: 'relative' }}
          transition={{ duration: 0.3 }}
        >
          <Flex
            basis='1'
            grow="0"
            position='fixed'
            top='0'
            left='0'
            right='0'
            bottom='0'
            backgroundColor='secondary.500'
            zIndex='2000'
            alignItems="center"
            justifyContent="center"    
            direction="column"   
          >
            <Flex justify={'flex-end'} px={4} py={3} textAlign="right" alignSelf="flex-end" mb="auto">
              <IconButton
                icon={
                  <CloseIcon w={3} h={3} />
                }
                variant={'ghost'}
                colorScheme={'whiteAlpha'}
                aria-label={'Close contact form'}
                onClick={closeModal}
              />
            </Flex>
            <form action="https://formspree.io/f/xayanvze" method="POST" style={{
              paddingLeft: '1rem', 
              paddingRight: '1rem', 
              width: '600px',
              maxWidth: '100%',
              marginBottom: 'auto',
            }} >
              <Heading as="h2" size="2xl" align="center" mb="4" color="white">Let's talk</Heading>
              <FormControl id="name" isRequired marginBottom="4">
                <FormLabel color="white">Name</FormLabel>
                <Input type="text" name="name" placeholder="Your name" variant="outline" color="white"/>
              </FormControl>
              <FormControl id="email" isRequired mb="4">
                <FormLabel color="white">Email address</FormLabel>
                <Input type="email" name="email" placeholder="Your email" variant="outline" color="white"/>
              </FormControl>
              <FormControl id="message" isRequired mb="4">
                <FormLabel color="white">Message</FormLabel>
                <Textarea name="message" rows="8" placeholder="Include things like company background, project details, useful links etc." resize="vertical" variant="outline" color="white"/>
              </FormControl>
              <Box textAlign="center" mt="6">
                <Button
                  colorScheme="whiteAlpha"
                  variant="outline"
                  type="submit"
                >
                  Send message
                </Button>
              </Box>
            </form>
          </Flex>
        </motion.div>
      : null }
    </AnimatePresence>
  );
}

export default ModalFormContact;